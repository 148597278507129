import { Fragment, useRef, useState, useEffect } from "react";
import PicturePop from "../components/PicturePop";
import "./Upload.css";

const backendUrl =
	window.location.hostname === "localhost"
		? "http://localhost:5001/dps-uploads/europe-west3/uploadPicture"
		: "https://upload.dpschool.io/api/uploadPicture";

const postPicture = (token, image, name) => {
	return fetch(backendUrl, {
		method: "POST",
		cache: "no-cache",
		headers: {
			Authorization: "Bearer " + token,
			"Content-Type": image.type,
			"X-Image": name,
		},
		body: image, // file or blob
	}).then((response) => {
		if (response.ok) return response;
		throw response;
	});
};

const downsizeImage = (imgToCompress, maxSize = 1500) => {
	return new Promise((resolvePromiseWithBlob) => {
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");
		const originalWidth = imgToCompress.width;
		const originalHeight = imgToCompress.height;
		let factor = 1;
		if (originalWidth > maxSize && originalWidth >= originalHeight) {
			factor = maxSize / originalWidth;
		}
		if (originalHeight > maxSize && originalHeight > originalWidth) {
			factor = maxSize / originalHeight;
		}
		const canvasWidth = originalWidth * factor; // Example: 80% of original width
		const canvasHeight = originalHeight * factor; // Example: 80% of original height
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
		context.drawImage(imgToCompress, 0, 0, canvasWidth, canvasHeight);
		canvas.toBlob(
			(blob) =>
				resolvePromiseWithBlob({
					blob,
					url: URL.createObjectURL(blob),
					type: "image/png",
				}),
			"image/png",
		);
	});
};

const readBlobFromFile = (file) => {
	return new Promise((resolvePromiseWithBlobAndUrlAndType) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			// Create a blob from the array buffer
			const blob = new Blob([event.target.result], { type: file.type });
			const blobUrl = URL.createObjectURL(blob);

			resolvePromiseWithBlobAndUrlAndType({
				blob,
				url: blobUrl,
				type: file.type,
			});
		};
		// Read the file as an array buffer
		reader.readAsArrayBuffer(file);
	});
};

/* UI States
	- no picture uploaded yet – empty frame
	- picture selected and preview is showing
	- if size is valid --> processing
	- if size is not valid --> warning

	- background removal processing: scanner is showing 
	- background removal done – image pops out, upload is started
	- image is uploading
	- success message "All done"
	*/

export default function Upload({ user, signOut }) {
	const fileInput = useRef(null);
	const [token, setToken] = useState(null);

	const [imageOriginal, setImageOriginal] = useState(null);
	const [imageTransparent, setImageTransparent] = useState(null);

	const [imageIsValid, setImageIsValid] = useState(false);

	const [showImage, setShowImage] = useState(false);
	const [showImageTrans, setShowImageTrans] = useState(false);
	const [hasProcessed, setHasProcessed] = useState(false);
	const [isUploadingTransparent, setIsUploadingTransparent] = useState(false);
	const [isUploadingOriginal, setIsUploadingOriginal] = useState(false);
	const [uploadOriginalOk, setUploadOriginalOk] = useState(false);
	const [uploadTransparentOk, setUploadTransparentOk] = useState(false);
	const [error, setError] = useState(null);
	const [warning, setWarning] = useState(null);

	const [pop, setPop] = useState(false);
	const [angle, setAngle] = useState(0);

	const onMove = (e) => {
		const relativeX = (e.pageX - e.view.innerWidth / 2) / e.view.innerWidth;
		setAngle(relativeX * 30);
	};

	const onClickSelect = () => {
		fileInput.current.click();
	};

	const onClickPicture = () => {
		if (imageOriginal === null) {
			fileInput.current.click();
		} else {
			setPop(!pop);
		}
	};

	const onImageSelected = async (event) => {
		const selectedFile = event.target.files[0];
		const imageBlobUrl = await readBlobFromFile(selectedFile);
		let resizedBlobUrl = null;

		// Reset state to "just selected"
		setPop(false);
		setShowImage(false);
		setShowImageTrans(false);
		setUploadOriginalOk(false);
		setUploadTransparentOk(false);
		setImageIsValid(false);
		setHasProcessed(false);
		setError(null);
		setWarning(null);
		setImageOriginal(null);
		setImageTransparent(null);

		// Sanity checks on image dimensions
		const img = new Image();
		img.onload = async function () {
			if (this.width < 500 || this.height < 500) {				
				setWarning("Please use a picture larger than 500 pixels");
			} else if (this.width > 1500 || this.height > 1500) {
				resizedBlobUrl = await downsizeImage(img, 1500);
				setWarning(null);
			} else {
				resizedBlobUrl = imageBlobUrl;
				setWarning(null);
			}

			if (resizedBlobUrl !== null) {
				setImageOriginal(resizedBlobUrl);
				window.setTimeout(() => setShowImage(true), 500);

				// Upload original image to backend
				setIsUploadingOriginal(true);
				postPicture(token, resizedBlobUrl.blob, "original")
					.then(() => {
						setIsUploadingOriginal(false);
						setUploadOriginalOk(true);
						console.log("Uploaded original");
					})
					.catch((error) => {
						setIsUploadingOriginal(false);
						console.error(error);
						setError("Could not upload picture – sorry");
					});
			}

			setImageIsValid(resizedBlobUrl !== null);
		};
		img.src = imageBlobUrl.url;

		// Trigger background removal
		//setFile(selectedFile);

		// Reset input to allow selecting the same image again
		event.target.value = "";
	};

	const isLoading = imageOriginal && !hasProcessed && imageIsValid ? true : false;
	const isUploading = isUploadingOriginal || isUploadingTransparent;
	const allDone = uploadOriginalOk && uploadTransparentOk ? true : false;

	useEffect(() => {
		user.getIdToken().then((token) => setToken(token));
	}, [user]);

	useEffect(() => {
		if (token && imageOriginal && imageIsValid && !hasProcessed) {
			fetch("https://ai-background-removal-f3m36uw7ma-ey.a.run.app/", {
				method: "POST", // *GET, POST, PUT, DELETE, etc.
				cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
				headers: {
					"Content-Type": imageOriginal.type,
					red: 0,
					green: 0,
					blue: 0,
					alpha: 0,
				},
				body: imageOriginal.blob,
			})
				.then((response) => {
					if (response.ok) {
						return response.blob();
					} else {
						console.error(response);
						setError("Could not process picture – sorry.");
					}
				})
				.then((blob) => {
					var dataUrl = URL.createObjectURL(blob);
					setImageTransparent({blob, url: dataUrl, type: "image/png"});

					setHasProcessed(true);
					window.setTimeout(() => {
						setShowImageTrans(true);
						window.setTimeout(() => setPop(true), 500);
					}, 100);

					// @todo - resize transparent image to fixed size before uploading?
					setIsUploadingTransparent(true);
					postPicture(token, blob, "transparent")
						.then(() => {
							setIsUploadingTransparent(false);
							setUploadTransparentOk(true);
							console.log("Uploaded transparent");
						})
						.catch((error) => {
							setIsUploadingTransparent(false);
							console.error(error);
							setError("Could not upload picture – sorry.");
						});
				});
		}
	}, [user, imageOriginal, hasProcessed, imageIsValid, token]);

	let bubbleClass = "hidden";
	if (warning) bubbleClass = "warn";
	if (error) bubbleClass = "err";
	if (allDone) bubbleClass = "confirm";

	return (
		<Fragment>
			<div
				className="Upload col center justify-center"
				onMouseMove={onMove}
			>
				<div className={`Bubble ${bubbleClass}`}>
					{error}
					{warning}
					{allDone &&
						"Thanks, your picture was uploaded and you're done."}
					{bubbleClass === "hidden" && <span>&nbsp;</span>}
				</div>

				<PicturePop
					imgBase={imageOriginal}
					imgPop={imageTransparent}
					angle={angle}
					showBase={showImage}
					showScanning={showImage && !showImageTrans && imageIsValid}
					showPop={showImageTrans}
					isPopping={pop}
					onClick={onClickPicture}
				/>

				<div className="col center">
					<input
						ref={fileInput}
						onChange={onImageSelected}
						type="file"
						accept="image/jpeg,image/png"
						className="hidden"
					/>
					<button
						className="large"
						onClick={onClickSelect}
						disabled={!token || isLoading || isUploading}
					>
						<span>
							{isLoading
								? "Processing"
								: isUploading
								? "Uploading"
								: "Select photo"}
						</span>
					</button>

					<p className="smallprint">
						We accept JPG and PNG images, larger than 500 pixels on
						each side and ideally square.
						<br />
						Please make sure that your head and hair is not cut off
						on top/left/right side.
					</p>
				</div>
			</div>
			{user && (
				<div className="Overlay top right">
					<button className="Logout" onClick={signOut}>
						<img src="/assets/icon-logout.svg" alt="Sign out" />
					</button>
				</div>
			)}
		</Fragment>
	);
}
