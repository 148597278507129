import "./PicturePop.css";

export default function PicturePop({
	imgBase,
	imgPop,
	angle,
	showBase,
	showScanning,
	showPop,
	isPopping,
	onClick,
}) {
	/* @todo – for some reason the CSS rotation is not applied to .img.scanner correctly... */

	return (
		<div
			className="PicturePop drop-target col center justify-center"
			onClick={onClick}
		>
			<img
				src="/assets/shadow.png"
				alt="Shadow"
				className="shadow"
				style={{
					transform: `rotateY(${angle}deg) rotateX(90deg)`,
				}}
			/>

			<div
				className="img frame"
				style={{
					backgroundImage: `url(/assets/picture-frame.svg)`,
					transform: `rotateY(${angle}deg) rotateX(${
						isPopping ? 95 : 0
					}deg)`,
					zIndex: 1,
				}}
			/>

			{imgBase && (
				<div
					className="img"
					style={{
						backgroundImage: `url(${imgBase.url}`,
						transform: `rotateY(${angle}deg) rotateX(${
							isPopping ? 95 : 0
						}deg) translateZ(${isPopping ? -20 : 0}px)`,
						zIndex: 2,
						opacity: showBase ? 1 : 0,
					}}
				/>
			)}

			{showScanning && (
				<div
					className="img scanner"
					style={{
						rotate:`y ${angle}deg`,
						zIndex: 3,
						opacity: 1,
					}}
				/>
			)}

			{imgPop && (
				<div
					className="img"
					style={{
						backgroundImage: `url(${imgPop.url})`,
						transform: `rotateY(${angle}deg) translateZ(${
							isPopping ? 10 : 3
						}px)`,
						zIndex: 4,
						opacity: showPop ? 1 : 0,
					}}
				/>
			)}

			<div
				className="img cover"
				style={{					
					transform: `rotateY(${angle}deg) rotateX(${
						isPopping ? 0 : -95
					}deg) translateZ(0vh)`,
					zIndex: 5,
				}}
			>Photo should only be cut off at the bottom now</div>

		</div>
	);
}
