import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthContext from "./AuthContext";

import Login from "./pages/Login";
import Upload from "./pages/Upload";

import { getFirebase } from "./utils/get-firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import "./App.css";

// eslint-disable-next-line no-unused-vars
const firebase = getFirebase();
const auth = getAuth();

function App() {
  const [user, setUser] = useState(null);

  const signOut = () => {
    auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // https://firebase.google.com/docs/reference/js/firebase.User
      } else {
        setUser(null);
      }
    });
    return function cleanup() {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <AuthContext.Consumer>
        {({ user }) => (
          <Router>
            <Routes>
              <Route
                path="/"
                element={
                  user ? (
                    <Upload user={user} signOut={signOut} />
                  ) : (
                    <Login user={user} />
                  )
                }
              />
              <Route path="login" element={<Login user={user} />} />
            </Routes>
          </Router>
        )}
      </AuthContext.Consumer>
    </AuthContext.Provider>
  );
}

export default App;
