import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import firebaseConfig from "../config/firebase-config.json";

// nice tutorial for this approach: https://designcode.io/react-hooks-handbook-intro-to-firebase

let instance;

function getFirebase() {
    if (typeof window !== "undefined") {
        if (instance) return instance;
        instance = initializeApp(firebaseConfig);
        return instance;
    }
    return null;
}

function getStore() {
    if (typeof window !== "undefined") {
        const firebaseApp = getFirebase();
        return getFirestore(firebaseApp);
    }
    return null;
}

export default getFirebase;
export { getFirebase, getStore };
